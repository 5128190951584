import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { addDays } from "date-fns";
import React, { useState } from "react";
import { useGetIngredientsForPlannedMealsQuery } from "../../generated/graphql";
const ViewPlan: React.FC = () => {
  const [now] = useState(new Date());
  const [startDate, setStartDate] = useState(now);
  const [endDate, setEndDate] = useState(addDays(now, 6));
  const { data, refetch } = useGetIngredientsForPlannedMealsQuery({
    variables: {
      startDate,
      endDate,
    },
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Shopping List</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel>Start Date</IonLabel>
            <IonDatetime
              value={startDate.toISOString()}
              onIonChange={(e) =>
                e.detail.value && setStartDate(new Date(e.detail.value))
              }
              displayFormat="MMM DD YYYY"
              placeholder="Select Date"
            ></IonDatetime>
          </IonItem>
          <IonItem>
            <IonLabel>End Date</IonLabel>
            <IonDatetime
              value={endDate.toISOString()}
              onIonChange={(e) =>
                e.detail.value && setEndDate(new Date(e.detail.value))
              }
              displayFormat="MMM DD YYYY"
              placeholder="Select Date"
            ></IonDatetime>
          </IonItem>
          <IonButton
            onClick={() => {
              refetch({ startDate, endDate });
            }}
            expand="full"
          >
            Generate Grocery List
          </IonButton>
          <IonListHeader>
            <IonLabel>Ingredients</IonLabel>
          </IonListHeader>
          {data?.ingredientsForPlannedMeals &&
            data.ingredientsForPlannedMeals.map((ing) => {
              return (
                <IonItem>
                  <b className="ion-padding-end">
                    {ing.amount} {ing.unit}
                  </b>
                  {ing.name}
                </IonItem>
              );
            })}
        </IonList>
      </IonContent>
    </IonPage>
  );
};
export default ViewPlan;
