import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRippleEffect,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { format } from "date-fns";
import { chevronBack, chevronForward } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import RecipeCard from "../../components/RecipeCard";
import {
  useCreatePlannedMealMutation,
  useGetRecipeQuery,
} from "../../generated/graphql";
import { daysFromRange, isSameDate } from "../../utils/time";
import "./style.css";

interface AddPlannedMealProps extends RouteComponentProps<{ id: string }> {}
const AddPlannedMeal: React.FC<AddPlannedMealProps> = ({ match, history }) => {
  const recipeId = parseInt(match.params.id);

  const { data, loading } = useGetRecipeQuery({
    variables: { recipeId },
  });

  const [createPlannedMeal, { data: plannedMealCreatedResult }] =
    useCreatePlannedMealMutation();
  const [showToast] = useIonToast();

  const [left, setLeft] = useState(-1);
  const [right, setRight] = useState(4);
  const [now] = useState(new Date());
  const [days, setDays] = useState(daysFromRange(now, left, right));
  const passedInDate = new URLSearchParams(window.location.search).get(
    "chooseDate"
  );
  const [selectedDate, setSelectedDate] = useState(
    passedInDate ? new Date(passedInDate) : now
  );
  const [amount, setAmount] = useState(1);

  useEffect(() => {
    setDays(daysFromRange(now, left, right));
  }, [left, right, now]);

  useEffect(() => {
    if (plannedMealCreatedResult?.createPlannedMeal) {
      showToast({
        message: "Meal Planned Succesfully",
        duration: 3000,
      });
      history.push("/planning");
    }
  }, [showToast, history, plannedMealCreatedResult]);

  if (loading) {
    return (
      <IonPage>
        <IonHeader>
          <IonBackButton defaultHref="/planning" />
        </IonHeader>
        <IonContent>
          <IonSpinner />
        </IonContent>
      </IonPage>
    );
  }
  if (!data || !data.recipe) {
    return (
      <IonPage>
        <IonHeader>
          <IonBackButton defaultHref="/planning" />
        </IonHeader>
        <IonContent>
          <p>Recipe not found</p>
        </IonContent>
      </IonPage>
    );
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/planning" />
          </IonButtons>
          <IonTitle>Add Meal to Plan</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <RecipeCard amount={amount} recipe={data.recipe} />
        <IonList>
          <IonItem>
            When
            <IonButton
              onClick={() => {
                setLeft(-1);
                setRight(4);
              }}
              className="ion-padding-start"
            >
              Jump to Today
            </IonButton>
          </IonItem>
          <IonRow className="weekNav">
            <IonCol
              className="ion-activatable ripple-parent"
              onClick={() => {
                console.log(`clicked chevronLeft`);
                setLeft(left - 1);
                setRight(right - 1);
              }}
            >
              <IonIcon icon={chevronBack} />
              <IonRippleEffect></IonRippleEffect>
            </IonCol>
            {days.map((date) => {
              const dayString = format(date, "iii"); //.charAt(0);
              const dayOfMonth = format(date, "d");
              const selected = isSameDate(date, selectedDate);
              return (
                <IonCol
                  key={dayOfMonth}
                  className={`ion-activatable ripple-parent${
                    selected ? " selected" : ""
                  }`}
                  onClick={() => {
                    setSelectedDate(date);
                  }}
                >
                  <div className="dayAndDate">
                    <span className="dayText">{dayString}</span>
                    <div className="circle">{dayOfMonth}</div>
                  </div>
                  <IonRippleEffect></IonRippleEffect>
                </IonCol>
              );
            })}

            <IonCol
              className="ion-activatable ripple-parent"
              onClick={() => {
                console.log(`clicked chevronRight`);
                setLeft(left + 1);
                setRight(right + 1);
              }}
            >
              <IonIcon icon={chevronForward} />
              <IonRippleEffect></IonRippleEffect>
            </IonCol>
          </IonRow>
          <IonItem>
            <IonLabel>Amount</IonLabel>
            <IonInput
              type="number"
              value={amount}
              placeholder="Enter an amount greater than 0"
              onIonChange={(e) =>
                e.detail.value && setAmount(parseFloat(e.detail.value))
              }
            ></IonInput>
          </IonItem>

          <IonButton
            onClick={async () => {
              await createPlannedMeal({
                variables: {
                  date: selectedDate,
                  quantity: amount,
                  recipeId,
                },
              });
              history.push("/planning");
            }}
            expand="full"
          >
            Confirm and Add
          </IonButton>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default AddPlannedMeal;
