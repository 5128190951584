import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import convert from "convert-units";
import { arrowBackOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import {
  CoreIngredientFragment,
  DetailedRecipeIngredientFragment,
  useCreateRecipeIngredientMutation,
} from "../../generated/graphql";
import { withApollo } from "../../utils/apollo";

interface CreateRecipeIngredientModalProps {
  closeModalAndSetRecipeIngredient: (
    _: DetailedRecipeIngredientFragment
  ) => void;
  cancelModal: () => void;
  ingredient: CoreIngredientFragment;
  recipeId: number;
}
const CreateRecipeIngredientModal: React.FC<CreateRecipeIngredientModalProps> =
  ({ closeModalAndSetRecipeIngredient, cancelModal, ingredient, recipeId }) => {
    const [displayUnit, setDisplayUnit] = useState(ingredient.unit);
    const [amount, setAmount] = useState(ingredient.amount);
    const [showToast] = useIonToast();
    const [createRecipeIngredient, { data, loading }] =
      useCreateRecipeIngredientMutation();

    const handleCreate = () => {
      if (amount <= 0) {
        return showToast({
          message: "Please enter an amount greater than 0",
          duration: 3000,
        });
      }

      createRecipeIngredient({
        variables: {
          recipeId,
          ingredientId: ingredient.id,
          amount,
          displayUnit,
        },
      });
      showToast({
        message: "Ingredient Added to Recipe",
        duration: 3000,
      });
    };

    useEffect(() => {
      console.log({ loading, data });
      if (!loading && !!data?.createRecipeIngredient) {
        closeModalAndSetRecipeIngredient(data.createRecipeIngredient);
      }
    }, [loading, data, closeModalAndSetRecipeIngredient]);

    return (
      <IonPage>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>Add Recipe Ingredient</IonTitle>
            <IonButtons slot="start">
              <IonButton onClick={cancelModal}>
                <IonIcon icon={arrowBackOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonList>
            <IonItem>
              <IonLabel position="stacked">Ingredient Name</IonLabel>
              <IonInput value={ingredient.name} readonly></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Amount</IonLabel>
              <IonInput
                value={amount}
                type="number"
                onIonChange={(e) =>
                  e.detail.value && setAmount(parseFloat(e.detail.value))
                }
              >
                {" "}
              </IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Unit</IonLabel>
              <IonSelect
                value={displayUnit}
                okText="Okay"
                cancelText="Dismiss"
                onIonChange={(e) => {
                  setDisplayUnit(e.detail.value);
                }}
              >
                {[displayUnit as convert.Unit]
                  .concat(
                    convert().possibilities(displayUnit as convert.Measure)
                  )
                  .map((unit) => {
                    const description = convert().describe(unit);
                    return (
                      <IonSelectOption value={description.abbr} key={unit}>
                        {amount === 1
                          ? description.singular
                          : description.plural}
                      </IonSelectOption>
                    );
                  })}
              </IonSelect>
            </IonItem>

            <IonButton onClick={handleCreate} expand="block">
              Create
            </IonButton>
          </IonList>
        </IonContent>
      </IonPage>
    );
  };
export default withApollo(CreateRecipeIngredientModal);
