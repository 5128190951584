import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};


export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type Ingredient = {
  __typename?: 'Ingredient';
  id: Scalars['Int'];
  name: Scalars['String'];
  amount: Scalars['Float'];
  unit: Scalars['String'];
  userId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
  recipeIngredient: Array<RecipeIngredient>;
};


export type IngredientRecipeIngredientArgs = {
  where?: Maybe<RecipeIngredientWhereInput>;
  orderBy?: Maybe<Array<RecipeIngredientOrderByInput>>;
  cursor?: Maybe<RecipeIngredientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<RecipeIngredientScalarFieldEnum>>;
};

export type IngredientListRelationFilter = {
  every?: Maybe<IngredientWhereInput>;
  some?: Maybe<IngredientWhereInput>;
  none?: Maybe<IngredientWhereInput>;
};

export type IngredientOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  unit?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type IngredientRelationFilter = {
  is?: Maybe<IngredientWhereInput>;
  isNot?: Maybe<IngredientWhereInput>;
};

export enum IngredientScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Amount = 'amount',
  Unit = 'unit',
  UserId = 'userId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type IngredientWhereInput = {
  AND?: Maybe<Array<IngredientWhereInput>>;
  OR?: Maybe<Array<IngredientWhereInput>>;
  NOT?: Maybe<Array<IngredientWhereInput>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  amount?: Maybe<FloatFilter>;
  unit?: Maybe<StringFilter>;
  userId?: Maybe<IntFilter>;
  user?: Maybe<UserRelationFilter>;
  recipeIngredient?: Maybe<RecipeIngredientListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type IngredientWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type IngredientsForPlannedMeal = {
  __typename?: 'IngredientsForPlannedMeal';
  name: Scalars['String'];
  unit: Scalars['String'];
  amount: Scalars['Float'];
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createIngredient: Ingredient;
  createPlannedMeal: PlannedMeal;
  updatePlannedMeal: PlannedMeal;
  deletePlannedMeal: Scalars['Boolean'];
  vote: Scalars['Boolean'];
  createPost: Post;
  updatePost?: Maybe<Post>;
  deletePost: Scalars['Boolean'];
  createRecipe: Recipe;
  updateRecipe: Recipe;
  createRecipeIngredient: RecipeIngredient;
  deleteRecipeIngredient: Scalars['Boolean'];
  updateRecipeIngredient: Scalars['Boolean'];
  changePassword: UserResponse;
  forgotPassword: Scalars['Boolean'];
  register: UserResponse;
  login: UserResponse;
  logout: Scalars['Boolean'];
};


export type MutationCreateIngredientArgs = {
  unit: Scalars['String'];
  amount: Scalars['Float'];
  name: Scalars['String'];
};


export type MutationCreatePlannedMealArgs = {
  date: Scalars['DateTime'];
  quantity: Scalars['Float'];
  recipeId: Scalars['Int'];
};


export type MutationUpdatePlannedMealArgs = {
  date?: Maybe<Scalars['DateTime']>;
  quantity?: Maybe<Scalars['Float']>;
  recipeId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationDeletePlannedMealArgs = {
  id: Scalars['Int'];
};


export type MutationVoteArgs = {
  value: Scalars['Int'];
  postId: Scalars['Int'];
};


export type MutationCreatePostArgs = {
  input: PostInput;
};


export type MutationUpdatePostArgs = {
  text: Scalars['String'];
  title: Scalars['String'];
  id: Scalars['Int'];
};


export type MutationDeletePostArgs = {
  id: Scalars['Int'];
};


export type MutationCreateRecipeArgs = {
  primaryImageUrl: Scalars['String'];
  cuisine: Scalars['String'];
  timeToPrepare: Scalars['Float'];
  numberOfServings: Scalars['Int'];
  content: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateRecipeArgs = {
  primaryImageUrl?: Maybe<Scalars['String']>;
  cuisine?: Maybe<Scalars['String']>;
  timeToPrepare?: Maybe<Scalars['Float']>;
  numberOfServings?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};


export type MutationCreateRecipeIngredientArgs = {
  displayUnit: Scalars['String'];
  amount: Scalars['Float'];
  recipeId: Scalars['Int'];
  ingredientId: Scalars['Int'];
};


export type MutationDeleteRecipeIngredientArgs = {
  id: Scalars['Int'];
};


export type MutationUpdateRecipeIngredientArgs = {
  displayUnit?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationRegisterArgs = {
  options: UsernamePasswordInput;
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  usernameOrEmail: Scalars['String'];
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type PaginatedPosts = {
  __typename?: 'PaginatedPosts';
  posts: Array<Post>;
  hasMore: Scalars['Boolean'];
};

export type PlannedMeal = {
  __typename?: 'PlannedMeal';
  id: Scalars['Int'];
  quantity: Scalars['Float'];
  date: Scalars['DateTime'];
  recipeId: Scalars['Int'];
  userId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  recipe: Recipe;
  user: User;
};

export type PlannedMealListRelationFilter = {
  every?: Maybe<PlannedMealWhereInput>;
  some?: Maybe<PlannedMealWhereInput>;
  none?: Maybe<PlannedMealWhereInput>;
};

export type PlannedMealOrderByInput = {
  id?: Maybe<SortOrder>;
  quantity?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  recipeId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum PlannedMealScalarFieldEnum {
  Id = 'id',
  Quantity = 'quantity',
  Date = 'date',
  RecipeId = 'recipeId',
  UserId = 'userId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type PlannedMealWhereInput = {
  AND?: Maybe<Array<PlannedMealWhereInput>>;
  OR?: Maybe<Array<PlannedMealWhereInput>>;
  NOT?: Maybe<Array<PlannedMealWhereInput>>;
  id?: Maybe<IntFilter>;
  quantity?: Maybe<FloatFilter>;
  date?: Maybe<DateTimeFilter>;
  recipe?: Maybe<RecipeRelationFilter>;
  recipeId?: Maybe<IntFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PlannedMealWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  id_userId?: Maybe<PlannedMealIdUserIdCompoundUniqueInput>;
};

export type Post = {
  __typename?: 'Post';
  id: Scalars['Int'];
  title: Scalars['String'];
  text: Scalars['String'];
  points: Scalars['Int'];
  userId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
  updoot: Array<Updoot>;
  textSnippet: Scalars['String'];
  voteStatus?: Maybe<Scalars['Int']>;
};


export type PostUpdootArgs = {
  where?: Maybe<UpdootWhereInput>;
  orderBy?: Maybe<Array<UpdootOrderByInput>>;
  cursor?: Maybe<UpdootWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UpdootScalarFieldEnum>>;
};

export type PostInput = {
  title: Scalars['String'];
  text: Scalars['String'];
};

export type PostListRelationFilter = {
  every?: Maybe<PostWhereInput>;
  some?: Maybe<PostWhereInput>;
  none?: Maybe<PostWhereInput>;
};

export type PostOrderByInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  text?: Maybe<SortOrder>;
  points?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PostRelationFilter = {
  is?: Maybe<PostWhereInput>;
  isNot?: Maybe<PostWhereInput>;
};

export enum PostScalarFieldEnum {
  Id = 'id',
  Title = 'title',
  Text = 'text',
  Points = 'points',
  UserId = 'userId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type PostWhereInput = {
  AND?: Maybe<Array<PostWhereInput>>;
  OR?: Maybe<Array<PostWhereInput>>;
  NOT?: Maybe<Array<PostWhereInput>>;
  id?: Maybe<IntFilter>;
  title?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
  points?: Maybe<IntFilter>;
  userId?: Maybe<IntFilter>;
  user?: Maybe<UserRelationFilter>;
  updoot?: Maybe<UpdootListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PostWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  id_userId?: Maybe<PostIdUserIdCompoundUniqueInput>;
};

export type Query = {
  __typename?: 'Query';
  hello: Scalars['String'];
  ingredients: Array<Ingredient>;
  ingredient?: Maybe<Ingredient>;
  plannedMeals: Array<PlannedMeal>;
  plannedMeal: PlannedMeal;
  ingredientsForPlannedMeals: Array<IngredientsForPlannedMeal>;
  posts: PaginatedPosts;
  post?: Maybe<Post>;
  recipeSearch: Array<Recipe>;
  recipe?: Maybe<Recipe>;
  recipes: Array<Recipe>;
  recipeIngredients: RecipeIngredient;
  recipeIngredient: RecipeIngredient;
  me?: Maybe<User>;
};


export type QueryIngredientsArgs = {
  searchString?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryIngredientArgs = {
  id: Scalars['Int'];
};


export type QueryPlannedMealsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryPlannedMealArgs = {
  id: Scalars['Int'];
};


export type QueryIngredientsForPlannedMealsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};


export type QueryPostsArgs = {
  cursor?: Maybe<Scalars['Int']>;
  limit: Scalars['Int'];
};


export type QueryPostArgs = {
  id: Scalars['Int'];
};


export type QueryRecipeSearchArgs = {
  limit?: Maybe<Scalars['Int']>;
  shouldSearchInContent: Scalars['Boolean'];
  searchString: Scalars['String'];
};


export type QueryRecipeArgs = {
  id: Scalars['Int'];
};


export type QueryRecipesArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type QueryRecipeIngredientsArgs = {
  recipeId: Scalars['Int'];
};


export type QueryRecipeIngredientArgs = {
  id: Scalars['Int'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Recipe = {
  __typename?: 'Recipe';
  id: Scalars['Int'];
  name: Scalars['String'];
  content: Scalars['String'];
  numberOfServings: Scalars['Int'];
  timeToPrepare: Scalars['Float'];
  cuisine: Scalars['String'];
  primaryImageUrl: Scalars['String'];
  userId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
  recipeIngredients: Array<RecipeIngredient>;
  plannedMeals: Array<PlannedMeal>;
};


export type RecipeRecipeIngredientsArgs = {
  where?: Maybe<RecipeIngredientWhereInput>;
  orderBy?: Maybe<Array<RecipeIngredientOrderByInput>>;
  cursor?: Maybe<RecipeIngredientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<RecipeIngredientScalarFieldEnum>>;
};


export type RecipePlannedMealsArgs = {
  where?: Maybe<PlannedMealWhereInput>;
  orderBy?: Maybe<Array<PlannedMealOrderByInput>>;
  cursor?: Maybe<PlannedMealWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PlannedMealScalarFieldEnum>>;
};

export type RecipeIngredient = {
  __typename?: 'RecipeIngredient';
  id: Scalars['Int'];
  amount: Scalars['Float'];
  displayUnit: Scalars['String'];
  recipeId: Scalars['Int'];
  ingredientId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  recipe: Recipe;
  ingredient: Ingredient;
};

export type RecipeIngredientListRelationFilter = {
  every?: Maybe<RecipeIngredientWhereInput>;
  some?: Maybe<RecipeIngredientWhereInput>;
  none?: Maybe<RecipeIngredientWhereInput>;
};

export type RecipeIngredientOrderByInput = {
  id?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  displayUnit?: Maybe<SortOrder>;
  recipeId?: Maybe<SortOrder>;
  ingredientId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export enum RecipeIngredientScalarFieldEnum {
  Id = 'id',
  Amount = 'amount',
  DisplayUnit = 'displayUnit',
  RecipeId = 'recipeId',
  IngredientId = 'ingredientId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type RecipeIngredientWhereInput = {
  AND?: Maybe<Array<RecipeIngredientWhereInput>>;
  OR?: Maybe<Array<RecipeIngredientWhereInput>>;
  NOT?: Maybe<Array<RecipeIngredientWhereInput>>;
  id?: Maybe<IntFilter>;
  amount?: Maybe<FloatFilter>;
  displayUnit?: Maybe<StringFilter>;
  recipe?: Maybe<RecipeRelationFilter>;
  recipeId?: Maybe<IntFilter>;
  ingredient?: Maybe<IngredientRelationFilter>;
  ingredientId?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RecipeIngredientWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type RecipeListRelationFilter = {
  every?: Maybe<RecipeWhereInput>;
  some?: Maybe<RecipeWhereInput>;
  none?: Maybe<RecipeWhereInput>;
};

export type RecipeOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  content?: Maybe<SortOrder>;
  numberOfServings?: Maybe<SortOrder>;
  timeToPrepare?: Maybe<SortOrder>;
  cuisine?: Maybe<SortOrder>;
  primaryImageUrl?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type RecipeRelationFilter = {
  is?: Maybe<RecipeWhereInput>;
  isNot?: Maybe<RecipeWhereInput>;
};

export enum RecipeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Content = 'content',
  NumberOfServings = 'numberOfServings',
  TimeToPrepare = 'timeToPrepare',
  Cuisine = 'cuisine',
  PrimaryImageUrl = 'primaryImageUrl',
  UserId = 'userId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt'
}

export type RecipeWhereInput = {
  AND?: Maybe<Array<RecipeWhereInput>>;
  OR?: Maybe<Array<RecipeWhereInput>>;
  NOT?: Maybe<Array<RecipeWhereInput>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  content?: Maybe<StringFilter>;
  numberOfServings?: Maybe<IntFilter>;
  timeToPrepare?: Maybe<FloatFilter>;
  cuisine?: Maybe<StringFilter>;
  primaryImageUrl?: Maybe<StringFilter>;
  user?: Maybe<UserRelationFilter>;
  userId?: Maybe<IntFilter>;
  recipeIngredients?: Maybe<RecipeIngredientListRelationFilter>;
  plannedMeals?: Maybe<PlannedMealListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type RecipeWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  id_userId?: Maybe<RecipeIdUserIdCompoundUniqueInput>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type Updoot = {
  __typename?: 'Updoot';
  value: Scalars['Int'];
  userId: Scalars['Int'];
  postId: Scalars['Int'];
  post: Post;
  user: User;
};

export type UpdootListRelationFilter = {
  every?: Maybe<UpdootWhereInput>;
  some?: Maybe<UpdootWhereInput>;
  none?: Maybe<UpdootWhereInput>;
};

export type UpdootOrderByInput = {
  value?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  postId?: Maybe<SortOrder>;
};

export enum UpdootScalarFieldEnum {
  Value = 'value',
  UserId = 'userId',
  PostId = 'postId'
}

export type UpdootWhereInput = {
  AND?: Maybe<Array<UpdootWhereInput>>;
  OR?: Maybe<Array<UpdootWhereInput>>;
  NOT?: Maybe<Array<UpdootWhereInput>>;
  value?: Maybe<IntFilter>;
  userId?: Maybe<IntFilter>;
  postId?: Maybe<IntFilter>;
  post?: Maybe<PostRelationFilter>;
  user?: Maybe<UserRelationFilter>;
};

export type UpdootWhereUniqueInput = {
  userId_postId?: Maybe<UpdootUserIdPostIdCompoundUniqueInput>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  username: Scalars['String'];
  email: Scalars['String'];
  createdAt: Scalars['DateTime'];
  ingredients: Array<Ingredient>;
  posts: Array<Post>;
  updoots: Array<Updoot>;
  recipes: Array<Recipe>;
  plannedMeals: Array<PlannedMeal>;
};


export type UserIngredientsArgs = {
  where?: Maybe<IngredientWhereInput>;
  orderBy?: Maybe<Array<IngredientOrderByInput>>;
  cursor?: Maybe<IngredientWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<IngredientScalarFieldEnum>>;
};


export type UserPostsArgs = {
  where?: Maybe<PostWhereInput>;
  orderBy?: Maybe<Array<PostOrderByInput>>;
  cursor?: Maybe<PostWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PostScalarFieldEnum>>;
};


export type UserUpdootsArgs = {
  where?: Maybe<UpdootWhereInput>;
  orderBy?: Maybe<Array<UpdootOrderByInput>>;
  cursor?: Maybe<UpdootWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<UpdootScalarFieldEnum>>;
};


export type UserRecipesArgs = {
  where?: Maybe<RecipeWhereInput>;
  orderBy?: Maybe<Array<RecipeOrderByInput>>;
  cursor?: Maybe<RecipeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<RecipeScalarFieldEnum>>;
};


export type UserPlannedMealsArgs = {
  where?: Maybe<PlannedMealWhereInput>;
  orderBy?: Maybe<Array<PlannedMealOrderByInput>>;
  cursor?: Maybe<PlannedMealWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PlannedMealScalarFieldEnum>>;
};

export type UserRelationFilter = {
  is?: Maybe<UserWhereInput>;
  isNot?: Maybe<UserWhereInput>;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  errors?: Maybe<Array<FieldError>>;
  user?: Maybe<User>;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  id?: Maybe<IntFilter>;
  username?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  password?: Maybe<StringFilter>;
  lastLoginAt?: Maybe<DateTimeFilter>;
  ingredients?: Maybe<IngredientListRelationFilter>;
  posts?: Maybe<PostListRelationFilter>;
  updoots?: Maybe<UpdootListRelationFilter>;
  recipes?: Maybe<RecipeListRelationFilter>;
  plannedMeals?: Maybe<PlannedMealListRelationFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type UsernamePasswordInput = {
  email: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
};

export type PlannedMealIdUserIdCompoundUniqueInput = {
  id: Scalars['Int'];
  userId: Scalars['Int'];
};

export type PostIdUserIdCompoundUniqueInput = {
  id: Scalars['Int'];
  userId: Scalars['Int'];
};

export type RecipeIdUserIdCompoundUniqueInput = {
  id: Scalars['Int'];
  userId: Scalars['Int'];
};

export type UpdootUserIdPostIdCompoundUniqueInput = {
  userId: Scalars['Int'];
  postId: Scalars['Int'];
};

export type CoreIngredientFragment = { __typename?: 'Ingredient', id: number, name: string, createdAt: any, unit: string, amount: number, userId: number, updatedAt: any };

export type DetailedRecipeIngredientFragment = { __typename?: 'RecipeIngredient', id: number, amount: number, displayUnit: string, ingredient: { __typename?: 'Ingredient', id: number, name: string, createdAt: any, unit: string, amount: number, userId: number, updatedAt: any } };

export type DisplayPlannedMealFragment = { __typename?: 'PlannedMeal', id: number, date: any, recipe: { __typename?: 'Recipe', name: string } };

export type DisplayableRecipeFragment = { __typename?: 'Recipe', id: number, name: string, numberOfServings: number, timeToPrepare: number, cuisine: string, primaryImageUrl: string };

export type PostSnippetFragment = { __typename?: 'Post', id: number, createdAt: any, updatedAt: any, title: string, points: number, textSnippet: string, voteStatus?: Maybe<number>, user: { __typename?: 'User', id: number, username: string } };

export type RegularErrorFragment = { __typename?: 'FieldError', field: string, message: string };

export type RegularUserFragment = { __typename?: 'User', id: number, username: string };

export type RegularUserResponseFragment = { __typename?: 'UserResponse', errors?: Maybe<Array<{ __typename?: 'FieldError', field: string, message: string }>>, user?: Maybe<{ __typename?: 'User', id: number, username: string }> };

export type ChangePasswordMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'UserResponse', errors?: Maybe<Array<{ __typename?: 'FieldError', field: string, message: string }>>, user?: Maybe<{ __typename?: 'User', id: number, username: string }> } };

export type CreateIngredientMutationVariables = Exact<{
  name: Scalars['String'];
  unit: Scalars['String'];
  amount: Scalars['Float'];
}>;


export type CreateIngredientMutation = { __typename?: 'Mutation', createIngredient: { __typename?: 'Ingredient', id: number, name: string, createdAt: any, unit: string, amount: number, userId: number, updatedAt: any } };

export type CreatePlannedMealMutationVariables = Exact<{
  date: Scalars['DateTime'];
  quantity: Scalars['Float'];
  recipeId: Scalars['Int'];
}>;


export type CreatePlannedMealMutation = { __typename?: 'Mutation', createPlannedMeal: { __typename?: 'PlannedMeal', quantity: number, id: number, recipe: { __typename?: 'Recipe', name: string } } };

export type CreatePostMutationVariables = Exact<{
  input: PostInput;
}>;


export type CreatePostMutation = { __typename?: 'Mutation', createPost: { __typename?: 'Post', id: number, createdAt: any, updatedAt: any, title: string, text: string, points: number, userId: number } };

export type CreateRecipeMutationVariables = Exact<{
  name: Scalars['String'];
  content: Scalars['String'];
  numberOfServings: Scalars['Int'];
  timeToPrepare: Scalars['Float'];
  cuisine: Scalars['String'];
  primaryImageUrl: Scalars['String'];
}>;


export type CreateRecipeMutation = { __typename?: 'Mutation', createRecipe: { __typename?: 'Recipe', id: number } };

export type CreateRecipeIngredientMutationVariables = Exact<{
  recipeId: Scalars['Int'];
  ingredientId: Scalars['Int'];
  amount: Scalars['Float'];
  displayUnit: Scalars['String'];
}>;


export type CreateRecipeIngredientMutation = { __typename?: 'Mutation', createRecipeIngredient: { __typename?: 'RecipeIngredient', id: number, amount: number, displayUnit: string, ingredient: { __typename?: 'Ingredient', id: number, name: string, createdAt: any, unit: string, amount: number, userId: number, updatedAt: any } } };

export type DeletePostMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePostMutation = { __typename?: 'Mutation', deletePost: boolean };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type LoginMutationVariables = Exact<{
  usernameOrEmail: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'UserResponse', errors?: Maybe<Array<{ __typename?: 'FieldError', field: string, message: string }>>, user?: Maybe<{ __typename?: 'User', id: number, username: string }> } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type RegisterMutationVariables = Exact<{
  options: UsernamePasswordInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'UserResponse', errors?: Maybe<Array<{ __typename?: 'FieldError', field: string, message: string }>>, user?: Maybe<{ __typename?: 'User', id: number, username: string }> } };

export type UpdatePostMutationVariables = Exact<{
  id: Scalars['Int'];
  title: Scalars['String'];
  text: Scalars['String'];
}>;


export type UpdatePostMutation = { __typename?: 'Mutation', updatePost?: Maybe<{ __typename?: 'Post', id: number, title: string, text: string, textSnippet: string }> };

export type UpdateRecipeMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  content: Scalars['String'];
  numberOfServings: Scalars['Int'];
  timeToPrepare: Scalars['Float'];
  cuisine: Scalars['String'];
  primaryImageUrl: Scalars['String'];
}>;


export type UpdateRecipeMutation = { __typename?: 'Mutation', updateRecipe: { __typename?: 'Recipe', id: number } };

export type VoteMutationVariables = Exact<{
  value: Scalars['Int'];
  postId: Scalars['Int'];
}>;


export type VoteMutation = { __typename?: 'Mutation', vote: boolean };

export type GetIngredientsForPlannedMealsQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type GetIngredientsForPlannedMealsQuery = { __typename?: 'Query', ingredientsForPlannedMeals: Array<{ __typename?: 'IngredientsForPlannedMeal', name: string, unit: string, amount: number }> };

export type GetIngredientsQueryVariables = Exact<{
  searchString?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetIngredientsQuery = { __typename?: 'Query', ingredients: Array<{ __typename?: 'Ingredient', id: number, name: string, createdAt: any, unit: string, amount: number, userId: number, updatedAt: any }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: Maybe<{ __typename?: 'User', id: number, username: string }> };

export type GetPlannedMealsQueryVariables = Exact<{
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type GetPlannedMealsQuery = { __typename?: 'Query', plannedMeals: Array<{ __typename?: 'PlannedMeal', id: number, date: any, recipe: { __typename?: 'Recipe', name: string } }> };

export type PostQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PostQuery = { __typename?: 'Query', post?: Maybe<{ __typename?: 'Post', id: number, createdAt: any, updatedAt: any, title: string, points: number, text: string, voteStatus?: Maybe<number>, user: { __typename?: 'User', id: number, username: string } }> };

export type PostsQueryVariables = Exact<{
  limit: Scalars['Int'];
  cursor?: Maybe<Scalars['Int']>;
}>;


export type PostsQuery = { __typename?: 'Query', posts: { __typename?: 'PaginatedPosts', hasMore: boolean, posts: Array<{ __typename?: 'Post', id: number, createdAt: any, updatedAt: any, title: string, points: number, textSnippet: string, voteStatus?: Maybe<number>, user: { __typename?: 'User', id: number, username: string } }> } };

export type GetRecipeQueryVariables = Exact<{
  recipeId: Scalars['Int'];
}>;


export type GetRecipeQuery = { __typename?: 'Query', recipe?: Maybe<{ __typename?: 'Recipe', id: number, name: string, content: string, numberOfServings: number, timeToPrepare: number, cuisine: string, primaryImageUrl: string, updatedAt: any, createdAt: any, user: { __typename?: 'User', username: string }, recipeIngredients: Array<{ __typename?: 'RecipeIngredient', id: number, amount: number, displayUnit: string, ingredient: { __typename?: 'Ingredient', id: number, name: string, createdAt: any, unit: string, amount: number, userId: number, updatedAt: any } }> }> };

export type RecipeSearchQueryVariables = Exact<{
  searchString: Scalars['String'];
  shouldSearchInContent?: Scalars['Boolean'];
}>;


export type RecipeSearchQuery = { __typename?: 'Query', recipeSearch: Array<{ __typename?: 'Recipe', id: number, name: string, numberOfServings: number, timeToPrepare: number, cuisine: string, primaryImageUrl: string }> };

export type RecipesQueryVariables = Exact<{ [key: string]: never; }>;


export type RecipesQuery = { __typename?: 'Query', recipes: Array<{ __typename?: 'Recipe', id: number, name: string, numberOfServings: number, timeToPrepare: number, cuisine: string, primaryImageUrl: string }> };

export const CoreIngredientFragmentDoc = gql`
    fragment CoreIngredient on Ingredient {
  id
  name
  createdAt
  unit
  amount
  userId
  createdAt
  updatedAt
}
    `;
export const DetailedRecipeIngredientFragmentDoc = gql`
    fragment DetailedRecipeIngredient on RecipeIngredient {
  id
  amount
  displayUnit
  ingredient {
    ...CoreIngredient
  }
}
    ${CoreIngredientFragmentDoc}`;
export const DisplayPlannedMealFragmentDoc = gql`
    fragment DisplayPlannedMeal on PlannedMeal {
  id
  recipe {
    name
  }
  date
}
    `;
export const DisplayableRecipeFragmentDoc = gql`
    fragment DisplayableRecipe on Recipe {
  id
  name
  numberOfServings
  timeToPrepare
  cuisine
  primaryImageUrl
}
    `;
export const PostSnippetFragmentDoc = gql`
    fragment PostSnippet on Post {
  id
  createdAt
  updatedAt
  title
  points
  textSnippet
  voteStatus
  user {
    id
    username
  }
}
    `;
export const RegularErrorFragmentDoc = gql`
    fragment RegularError on FieldError {
  field
  message
}
    `;
export const RegularUserFragmentDoc = gql`
    fragment RegularUser on User {
  id
  username
}
    `;
export const RegularUserResponseFragmentDoc = gql`
    fragment RegularUserResponse on UserResponse {
  errors {
    ...RegularError
  }
  user {
    ...RegularUser
  }
}
    ${RegularErrorFragmentDoc}
${RegularUserFragmentDoc}`;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($token: String!, $newPassword: String!) {
  changePassword(token: $token, newPassword: $newPassword) {
    ...RegularUserResponse
  }
}
    ${RegularUserResponseFragmentDoc}`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateIngredientDocument = gql`
    mutation createIngredient($name: String!, $unit: String!, $amount: Float!) {
  createIngredient(name: $name, unit: $unit, amount: $amount) {
    ...CoreIngredient
  }
}
    ${CoreIngredientFragmentDoc}`;
export type CreateIngredientMutationFn = Apollo.MutationFunction<CreateIngredientMutation, CreateIngredientMutationVariables>;

/**
 * __useCreateIngredientMutation__
 *
 * To run a mutation, you first call `useCreateIngredientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIngredientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIngredientMutation, { data, loading, error }] = useCreateIngredientMutation({
 *   variables: {
 *      name: // value for 'name'
 *      unit: // value for 'unit'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useCreateIngredientMutation(baseOptions?: Apollo.MutationHookOptions<CreateIngredientMutation, CreateIngredientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIngredientMutation, CreateIngredientMutationVariables>(CreateIngredientDocument, options);
      }
export type CreateIngredientMutationHookResult = ReturnType<typeof useCreateIngredientMutation>;
export type CreateIngredientMutationResult = Apollo.MutationResult<CreateIngredientMutation>;
export type CreateIngredientMutationOptions = Apollo.BaseMutationOptions<CreateIngredientMutation, CreateIngredientMutationVariables>;
export const CreatePlannedMealDocument = gql`
    mutation CreatePlannedMeal($date: DateTime!, $quantity: Float!, $recipeId: Int!) {
  createPlannedMeal(date: $date, quantity: $quantity, recipeId: $recipeId) {
    quantity
    id
    recipe {
      name
    }
  }
}
    `;
export type CreatePlannedMealMutationFn = Apollo.MutationFunction<CreatePlannedMealMutation, CreatePlannedMealMutationVariables>;

/**
 * __useCreatePlannedMealMutation__
 *
 * To run a mutation, you first call `useCreatePlannedMealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlannedMealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlannedMealMutation, { data, loading, error }] = useCreatePlannedMealMutation({
 *   variables: {
 *      date: // value for 'date'
 *      quantity: // value for 'quantity'
 *      recipeId: // value for 'recipeId'
 *   },
 * });
 */
export function useCreatePlannedMealMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlannedMealMutation, CreatePlannedMealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlannedMealMutation, CreatePlannedMealMutationVariables>(CreatePlannedMealDocument, options);
      }
export type CreatePlannedMealMutationHookResult = ReturnType<typeof useCreatePlannedMealMutation>;
export type CreatePlannedMealMutationResult = Apollo.MutationResult<CreatePlannedMealMutation>;
export type CreatePlannedMealMutationOptions = Apollo.BaseMutationOptions<CreatePlannedMealMutation, CreatePlannedMealMutationVariables>;
export const CreatePostDocument = gql`
    mutation CreatePost($input: PostInput!) {
  createPost(input: $input) {
    id
    createdAt
    updatedAt
    title
    text
    points
    userId
  }
}
    `;
export type CreatePostMutationFn = Apollo.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePostMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, options);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const CreateRecipeDocument = gql`
    mutation CreateRecipe($name: String!, $content: String!, $numberOfServings: Int!, $timeToPrepare: Float!, $cuisine: String!, $primaryImageUrl: String!) {
  createRecipe(
    name: $name
    content: $content
    numberOfServings: $numberOfServings
    timeToPrepare: $timeToPrepare
    cuisine: $cuisine
    primaryImageUrl: $primaryImageUrl
  ) {
    id
  }
}
    `;
export type CreateRecipeMutationFn = Apollo.MutationFunction<CreateRecipeMutation, CreateRecipeMutationVariables>;

/**
 * __useCreateRecipeMutation__
 *
 * To run a mutation, you first call `useCreateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecipeMutation, { data, loading, error }] = useCreateRecipeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      content: // value for 'content'
 *      numberOfServings: // value for 'numberOfServings'
 *      timeToPrepare: // value for 'timeToPrepare'
 *      cuisine: // value for 'cuisine'
 *      primaryImageUrl: // value for 'primaryImageUrl'
 *   },
 * });
 */
export function useCreateRecipeMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecipeMutation, CreateRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecipeMutation, CreateRecipeMutationVariables>(CreateRecipeDocument, options);
      }
export type CreateRecipeMutationHookResult = ReturnType<typeof useCreateRecipeMutation>;
export type CreateRecipeMutationResult = Apollo.MutationResult<CreateRecipeMutation>;
export type CreateRecipeMutationOptions = Apollo.BaseMutationOptions<CreateRecipeMutation, CreateRecipeMutationVariables>;
export const CreateRecipeIngredientDocument = gql`
    mutation CreateRecipeIngredient($recipeId: Int!, $ingredientId: Int!, $amount: Float!, $displayUnit: String!) {
  createRecipeIngredient(
    recipeId: $recipeId
    ingredientId: $ingredientId
    amount: $amount
    displayUnit: $displayUnit
  ) {
    ...DetailedRecipeIngredient
  }
}
    ${DetailedRecipeIngredientFragmentDoc}`;
export type CreateRecipeIngredientMutationFn = Apollo.MutationFunction<CreateRecipeIngredientMutation, CreateRecipeIngredientMutationVariables>;

/**
 * __useCreateRecipeIngredientMutation__
 *
 * To run a mutation, you first call `useCreateRecipeIngredientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecipeIngredientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecipeIngredientMutation, { data, loading, error }] = useCreateRecipeIngredientMutation({
 *   variables: {
 *      recipeId: // value for 'recipeId'
 *      ingredientId: // value for 'ingredientId'
 *      amount: // value for 'amount'
 *      displayUnit: // value for 'displayUnit'
 *   },
 * });
 */
export function useCreateRecipeIngredientMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecipeIngredientMutation, CreateRecipeIngredientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecipeIngredientMutation, CreateRecipeIngredientMutationVariables>(CreateRecipeIngredientDocument, options);
      }
export type CreateRecipeIngredientMutationHookResult = ReturnType<typeof useCreateRecipeIngredientMutation>;
export type CreateRecipeIngredientMutationResult = Apollo.MutationResult<CreateRecipeIngredientMutation>;
export type CreateRecipeIngredientMutationOptions = Apollo.BaseMutationOptions<CreateRecipeIngredientMutation, CreateRecipeIngredientMutationVariables>;
export const DeletePostDocument = gql`
    mutation DeletePost($id: Int!) {
  deletePost(id: $id)
}
    `;
export type DeletePostMutationFn = Apollo.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LoginDocument = gql`
    mutation Login($usernameOrEmail: String!, $password: String!) {
  login(usernameOrEmail: $usernameOrEmail, password: $password) {
    ...RegularUserResponse
  }
}
    ${RegularUserResponseFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      usernameOrEmail: // value for 'usernameOrEmail'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($options: UsernamePasswordInput!) {
  register(options: $options) {
    ...RegularUserResponse
  }
}
    ${RegularUserResponseFragmentDoc}`;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const UpdatePostDocument = gql`
    mutation UpdatePost($id: Int!, $title: String!, $text: String!) {
  updatePost(id: $id, title: $title, text: $text) {
    id
    title
    text
    textSnippet
  }
}
    `;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdatePostMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, options);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<UpdatePostMutation, UpdatePostMutationVariables>;
export const UpdateRecipeDocument = gql`
    mutation UpdateRecipe($id: Int!, $name: String!, $content: String!, $numberOfServings: Int!, $timeToPrepare: Float!, $cuisine: String!, $primaryImageUrl: String!) {
  updateRecipe(
    id: $id
    name: $name
    content: $content
    numberOfServings: $numberOfServings
    timeToPrepare: $timeToPrepare
    cuisine: $cuisine
    primaryImageUrl: $primaryImageUrl
  ) {
    id
  }
}
    `;
export type UpdateRecipeMutationFn = Apollo.MutationFunction<UpdateRecipeMutation, UpdateRecipeMutationVariables>;

/**
 * __useUpdateRecipeMutation__
 *
 * To run a mutation, you first call `useUpdateRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecipeMutation, { data, loading, error }] = useUpdateRecipeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      content: // value for 'content'
 *      numberOfServings: // value for 'numberOfServings'
 *      timeToPrepare: // value for 'timeToPrepare'
 *      cuisine: // value for 'cuisine'
 *      primaryImageUrl: // value for 'primaryImageUrl'
 *   },
 * });
 */
export function useUpdateRecipeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecipeMutation, UpdateRecipeMutationVariables>(UpdateRecipeDocument, options);
      }
export type UpdateRecipeMutationHookResult = ReturnType<typeof useUpdateRecipeMutation>;
export type UpdateRecipeMutationResult = Apollo.MutationResult<UpdateRecipeMutation>;
export type UpdateRecipeMutationOptions = Apollo.BaseMutationOptions<UpdateRecipeMutation, UpdateRecipeMutationVariables>;
export const VoteDocument = gql`
    mutation Vote($value: Int!, $postId: Int!) {
  vote(value: $value, postId: $postId)
}
    `;
export type VoteMutationFn = Apollo.MutationFunction<VoteMutation, VoteMutationVariables>;

/**
 * __useVoteMutation__
 *
 * To run a mutation, you first call `useVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voteMutation, { data, loading, error }] = useVoteMutation({
 *   variables: {
 *      value: // value for 'value'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useVoteMutation(baseOptions?: Apollo.MutationHookOptions<VoteMutation, VoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoteMutation, VoteMutationVariables>(VoteDocument, options);
      }
export type VoteMutationHookResult = ReturnType<typeof useVoteMutation>;
export type VoteMutationResult = Apollo.MutationResult<VoteMutation>;
export type VoteMutationOptions = Apollo.BaseMutationOptions<VoteMutation, VoteMutationVariables>;
export const GetIngredientsForPlannedMealsDocument = gql`
    query GetIngredientsForPlannedMeals($startDate: DateTime, $endDate: DateTime) {
  ingredientsForPlannedMeals(startDate: $startDate, endDate: $endDate) {
    name
    unit
    amount
  }
}
    `;

/**
 * __useGetIngredientsForPlannedMealsQuery__
 *
 * To run a query within a React component, call `useGetIngredientsForPlannedMealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIngredientsForPlannedMealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIngredientsForPlannedMealsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetIngredientsForPlannedMealsQuery(baseOptions?: Apollo.QueryHookOptions<GetIngredientsForPlannedMealsQuery, GetIngredientsForPlannedMealsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIngredientsForPlannedMealsQuery, GetIngredientsForPlannedMealsQueryVariables>(GetIngredientsForPlannedMealsDocument, options);
      }
export function useGetIngredientsForPlannedMealsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIngredientsForPlannedMealsQuery, GetIngredientsForPlannedMealsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIngredientsForPlannedMealsQuery, GetIngredientsForPlannedMealsQueryVariables>(GetIngredientsForPlannedMealsDocument, options);
        }
export type GetIngredientsForPlannedMealsQueryHookResult = ReturnType<typeof useGetIngredientsForPlannedMealsQuery>;
export type GetIngredientsForPlannedMealsLazyQueryHookResult = ReturnType<typeof useGetIngredientsForPlannedMealsLazyQuery>;
export type GetIngredientsForPlannedMealsQueryResult = Apollo.QueryResult<GetIngredientsForPlannedMealsQuery, GetIngredientsForPlannedMealsQueryVariables>;
export const GetIngredientsDocument = gql`
    query GetIngredients($searchString: String, $limit: Int = 50) {
  ingredients(searchString: $searchString, limit: $limit) {
    ...CoreIngredient
  }
}
    ${CoreIngredientFragmentDoc}`;

/**
 * __useGetIngredientsQuery__
 *
 * To run a query within a React component, call `useGetIngredientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIngredientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIngredientsQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetIngredientsQuery(baseOptions?: Apollo.QueryHookOptions<GetIngredientsQuery, GetIngredientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIngredientsQuery, GetIngredientsQueryVariables>(GetIngredientsDocument, options);
      }
export function useGetIngredientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIngredientsQuery, GetIngredientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIngredientsQuery, GetIngredientsQueryVariables>(GetIngredientsDocument, options);
        }
export type GetIngredientsQueryHookResult = ReturnType<typeof useGetIngredientsQuery>;
export type GetIngredientsLazyQueryHookResult = ReturnType<typeof useGetIngredientsLazyQuery>;
export type GetIngredientsQueryResult = Apollo.QueryResult<GetIngredientsQuery, GetIngredientsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...RegularUser
  }
}
    ${RegularUserFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetPlannedMealsDocument = gql`
    query GetPlannedMeals($startDate: DateTime!, $endDate: DateTime!) {
  plannedMeals(startDate: $startDate, endDate: $endDate) {
    ...DisplayPlannedMeal
  }
}
    ${DisplayPlannedMealFragmentDoc}`;

/**
 * __useGetPlannedMealsQuery__
 *
 * To run a query within a React component, call `useGetPlannedMealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlannedMealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlannedMealsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetPlannedMealsQuery(baseOptions: Apollo.QueryHookOptions<GetPlannedMealsQuery, GetPlannedMealsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlannedMealsQuery, GetPlannedMealsQueryVariables>(GetPlannedMealsDocument, options);
      }
export function useGetPlannedMealsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlannedMealsQuery, GetPlannedMealsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlannedMealsQuery, GetPlannedMealsQueryVariables>(GetPlannedMealsDocument, options);
        }
export type GetPlannedMealsQueryHookResult = ReturnType<typeof useGetPlannedMealsQuery>;
export type GetPlannedMealsLazyQueryHookResult = ReturnType<typeof useGetPlannedMealsLazyQuery>;
export type GetPlannedMealsQueryResult = Apollo.QueryResult<GetPlannedMealsQuery, GetPlannedMealsQueryVariables>;
export const PostDocument = gql`
    query Post($id: Int!) {
  post(id: $id) {
    id
    createdAt
    updatedAt
    title
    points
    text
    voteStatus
    user {
      id
      username
    }
  }
}
    `;

/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostQuery(baseOptions: Apollo.QueryHookOptions<PostQuery, PostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostQuery, PostQueryVariables>(PostDocument, options);
      }
export function usePostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostQuery, PostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostQuery, PostQueryVariables>(PostDocument, options);
        }
export type PostQueryHookResult = ReturnType<typeof usePostQuery>;
export type PostLazyQueryHookResult = ReturnType<typeof usePostLazyQuery>;
export type PostQueryResult = Apollo.QueryResult<PostQuery, PostQueryVariables>;
export const PostsDocument = gql`
    query Posts($limit: Int!, $cursor: Int) {
  posts(limit: $limit, cursor: $cursor) {
    hasMore
    posts {
      ...PostSnippet
    }
  }
}
    ${PostSnippetFragmentDoc}`;

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function usePostsQuery(baseOptions: Apollo.QueryHookOptions<PostsQuery, PostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
      }
export function usePostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsQuery, PostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
        }
export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>;
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>;
export type PostsQueryResult = Apollo.QueryResult<PostsQuery, PostsQueryVariables>;
export const GetRecipeDocument = gql`
    query GetRecipe($recipeId: Int!) {
  recipe(id: $recipeId) {
    id
    name
    content
    numberOfServings
    timeToPrepare
    cuisine
    primaryImageUrl
    updatedAt
    createdAt
    user {
      username
    }
    recipeIngredients {
      ...DetailedRecipeIngredient
    }
  }
}
    ${DetailedRecipeIngredientFragmentDoc}`;

/**
 * __useGetRecipeQuery__
 *
 * To run a query within a React component, call `useGetRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeQuery({
 *   variables: {
 *      recipeId: // value for 'recipeId'
 *   },
 * });
 */
export function useGetRecipeQuery(baseOptions: Apollo.QueryHookOptions<GetRecipeQuery, GetRecipeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecipeQuery, GetRecipeQueryVariables>(GetRecipeDocument, options);
      }
export function useGetRecipeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecipeQuery, GetRecipeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecipeQuery, GetRecipeQueryVariables>(GetRecipeDocument, options);
        }
export type GetRecipeQueryHookResult = ReturnType<typeof useGetRecipeQuery>;
export type GetRecipeLazyQueryHookResult = ReturnType<typeof useGetRecipeLazyQuery>;
export type GetRecipeQueryResult = Apollo.QueryResult<GetRecipeQuery, GetRecipeQueryVariables>;
export const RecipeSearchDocument = gql`
    query RecipeSearch($searchString: String!, $shouldSearchInContent: Boolean! = false) {
  recipeSearch(
    shouldSearchInContent: $shouldSearchInContent
    searchString: $searchString
  ) {
    ...DisplayableRecipe
  }
}
    ${DisplayableRecipeFragmentDoc}`;

/**
 * __useRecipeSearchQuery__
 *
 * To run a query within a React component, call `useRecipeSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeSearchQuery({
 *   variables: {
 *      searchString: // value for 'searchString'
 *      shouldSearchInContent: // value for 'shouldSearchInContent'
 *   },
 * });
 */
export function useRecipeSearchQuery(baseOptions: Apollo.QueryHookOptions<RecipeSearchQuery, RecipeSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecipeSearchQuery, RecipeSearchQueryVariables>(RecipeSearchDocument, options);
      }
export function useRecipeSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipeSearchQuery, RecipeSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecipeSearchQuery, RecipeSearchQueryVariables>(RecipeSearchDocument, options);
        }
export type RecipeSearchQueryHookResult = ReturnType<typeof useRecipeSearchQuery>;
export type RecipeSearchLazyQueryHookResult = ReturnType<typeof useRecipeSearchLazyQuery>;
export type RecipeSearchQueryResult = Apollo.QueryResult<RecipeSearchQuery, RecipeSearchQueryVariables>;
export const RecipesDocument = gql`
    query Recipes {
  recipes {
    ...DisplayableRecipe
  }
}
    ${DisplayableRecipeFragmentDoc}`;

/**
 * __useRecipesQuery__
 *
 * To run a query within a React component, call `useRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecipesQuery(baseOptions?: Apollo.QueryHookOptions<RecipesQuery, RecipesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecipesQuery, RecipesQueryVariables>(RecipesDocument, options);
      }
export function useRecipesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecipesQuery, RecipesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecipesQuery, RecipesQueryVariables>(RecipesDocument, options);
        }
export type RecipesQueryHookResult = ReturnType<typeof useRecipesQuery>;
export type RecipesLazyQueryHookResult = ReturnType<typeof useRecipesLazyQuery>;
export type RecipesQueryResult = Apollo.QueryResult<RecipesQuery, RecipesQueryVariables>;