import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { personCircleOutline, addCircleOutline } from "ionicons/icons";
import { useMeQuery } from "../../generated/graphql";
import "./style.css";

interface NavigationProps {
  hideUserIcon?: boolean;
  hideAddIcon?: boolean;
}
export const Navigation: React.FC<NavigationProps> = ({
  hideAddIcon = false,
  hideUserIcon = false,
}) => {
  const { data, loading } = useMeQuery({
    fetchPolicy: "network-only",
  });
  const isLoggedIn = !loading && !!data?.me?.username;

  return (
    <IonHeader>
      <IonToolbar>
        <IonTitle size="large">logo</IonTitle>

        <IonButtons slot="primary">
          {!hideUserIcon && isLoggedIn ? (
            <IonButton routerLink="/login">
              <IonIcon slot="start" size="large" icon={personCircleOutline} />
            </IonButton>
          ) : (
            <IonButton routerLink="/login">Login</IonButton>
          )}
          {!hideAddIcon && (
            <IonButton routerLink="/create">
              <IonIcon slot="start" size="large" icon={addCircleOutline} />
            </IonButton>
          )}
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};
