import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { calendar, search, star } from "ionicons/icons";
import HomePage from "./pages/HomePage";
import PlanningPage from "./pages/PlanningPage";
import RecipeDetail from "./pages/RecipeDetail";
import Search from "./pages/SearchPage";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import CreateRecipe from "./pages/CreateRecipe";
import RegisterPage from "./pages/RegisterPage";
import LoginPage from "./pages/LoginPage";
import EditRecipe from "./pages/EditRecipe";
import AddPlannedMeal from "./pages/AddPlannedMeal";
import ViewPlan from "./pages/ViewPlan";

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route exact path="/home" component={HomePage} />
            <Route exact path="/planning" component={PlanningPage} />
            <Route path="/planning/search" component={Search} />
            <Route path="/planning/add/:id" component={AddPlannedMeal} />
            <Route path="/planning/view" component={ViewPlan} />
            <Route path="/search" component={Search} />
            <Route path="/recipe/:id" component={RecipeDetail} />
            <Route path="/edit/recipe/:id" component={EditRecipe} />
            <Route path="/create" component={CreateRecipe} />
            <Route path="/register" component={RegisterPage} />
            <Route path="/login" component={LoginPage} />
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
          </IonRouterOutlet>

          <IonTabBar slot="bottom">
            <IonTabButton tab="home" href="/home">
              <IonIcon icon={star} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>
            <IonTabButton tab="planning" href="/planning">
              <IonIcon icon={calendar} />
              <IonLabel>Planning</IonLabel>
            </IonTabButton>
            <IonTabButton tab="search" href="/search">
              <IonIcon icon={search} />
              <IonLabel>Search</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
