import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import React from "react";
import Editor from "rich-markdown-editor";
import KeyExtension from "./KeyExtension";

interface RecipeEditorProps {
  value?: string;
  placeholder: string;
  onChange?: (value: () => string) => void;
  addIngredient?: (
    ing: string,
    state: EditorState<any>,
    match: string[],
    view: EditorView<any>,
    callback: (_: string) => void
  ) => void;
}
export const RecipeEditor: React.FC<RecipeEditorProps> = ({
  addIngredient = () => {},
  ...props
}) => {
  const prefersDark = window.matchMedia("(prefers-color-scheme: light)");

  return (
    <Editor
      dark={prefersDark.matches}
      className="ion-padding"
      {...props}
      extensions={[
        new KeyExtension(
          (state: EditorState<any>, match: string[], view: EditorView<any>) => {
            addIngredient(
              match[1],
              state,
              match,
              view,
              (ingredientName: string) => {
                view.dispatch(
                  view.state.tr.insertText(
                    `${ingredientName}`,
                    state.selection.$from.pos - (match[1] || "").length + 1
                  )
                );
              }
            );
          },
          "@",
          "add ingredient",
          {}
        ),
      ]}
    />
  );
};
