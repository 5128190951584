import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonListHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonModal,
} from "@ionic/react";
import { useState } from "react";
import {
  CoreIngredientFragment,
  DetailedRecipeIngredientFragment,
  useGetIngredientsQuery,
} from "../../generated/graphql";
import { withApollo } from "../../utils/apollo";
import CreateIngredientModal from "./createIngredient";
import CreateRecipeIngredientModal from "./createRecipeIngredient";

interface AddIngredientModalProps {
  initialIngredient: string;
  closeModalAndSetIngredient: (_: DetailedRecipeIngredientFragment) => void;
  cancelModal: () => void;
  recipeId: number;
}
const AddIngredientModal: React.FC<AddIngredientModalProps> = ({
  initialIngredient,
  cancelModal,
  closeModalAndSetIngredient,
  recipeId,
}) => {
  const [ingName, setIngName] = useState(initialIngredient);
  const {
    data: ingredientsData,
    loading: ingredientsDataLoading,
    refetch: getIngredients,
  } = useGetIngredientsQuery();

  const refreshResults = () => {
    getIngredients({
      searchString: ingName === "" ? null : ingName,
    });
  };

  const [selectedIngredient, setSelectedIngredient] =
    useState<CoreIngredientFragment | null>(null);

  const [showCreateIngredientModal, hideCreateIngredientModal] = useIonModal(
    CreateIngredientModal,
    {
      initialIngredient: ingName,
      closeModalAndSetIngredient: (ing: CoreIngredientFragment) => {
        setIngName(ing.name);
        hideCreateIngredientModal();
        refreshResults();
        setSelectedIngredient(ing);
      },
      cancelModal: () => {
        hideCreateIngredientModal();
      },
    }
  );

  const [showCreateRecipeIngredientModal, hideCreateRecipeIngredientModal] =
    useIonModal(CreateRecipeIngredientModal, {
      closeModalAndSetRecipeIngredient: (
        ri: DetailedRecipeIngredientFragment
      ) => {
        hideCreateRecipeIngredientModal();
        closeModalAndSetIngredient(ri);
      },
      cancelModal: () => {
        hideCreateRecipeIngredientModal();
      },
      ingredient: selectedIngredient,
      recipeId,
    });

  const filteredIngredients =
    ingredientsData?.ingredients.filter((ing) =>
      ing.name.toLowerCase().includes((ingName || "").toLowerCase())
    ) || [];

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Add Ingredient</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => cancelModal()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonInput
              value={ingName}
              autofocus
              placeholder="Ingredient Name"
              clearInput
              onIonChange={(e) => setIngName(e.detail.value!)}
            ></IonInput>
            <IonButton slot="end" onClick={(_) => refreshResults()}>
              Refresh Results
            </IonButton>
          </IonItem>
        </IonList>
        {ingredientsDataLoading && <IonSpinner />}
        {ingredientsData && (
          <>
            <IonListHeader>Ingredients Found</IonListHeader>
            {filteredIngredients.map((ing) => (
              <IonItem
                key={ing.id}
                onClick={() => {
                  setSelectedIngredient(ing);
                  showCreateRecipeIngredientModal();
                  // closeModalAndSetIngredient(ing.name);
                }}
              >
                {ing.name}
              </IonItem>
            ))}
          </>
        )}

        {filteredIngredients.length === 0 && (
          <div className="container">
            <p>No ingredients matched that query</p>
            <IonButton
              onClick={() => {
                showCreateIngredientModal();
              }}
            >
              Create Ingredient?
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withApollo(AddIngredientModal);
