import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
} from "@ionic/react";
import { Navigation } from "../../components/Navigation";
import RecipeCard from "../../components/RecipeCard";
import { useRecipesQuery } from "../../generated/graphql";
import "./HomePage.css";

const HomePage: React.FC = () => {
  const { data, loading } = useRecipesQuery();
  return (
    <IonPage>
      <Navigation />
      <IonContent fullscreen className="ion-padding">
        <IonText>
          <h1>Just Added</h1>
        </IonText>
        <IonGrid>
          <IonRow>
            {!data || loading ? (
              <div className="container">
                <IonSpinner />
              </div>
            ) : (
              data.recipes.map((recipe) => {
                return (
                  <IonCol key={recipe.id} size-sm size="12" sizeLg="3">
                    <RecipeCard recipe={recipe} />
                  </IonCol>
                );
              })
            )}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
