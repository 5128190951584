import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonModal,
} from "@ionic/react";
import { add, pencil, receipt, share } from "ionicons/icons";
import React from "react";
import { RouteComponentProps } from "react-router";
import Editor from "rich-markdown-editor";
import {
  DetailedRecipeIngredientFragment,
  useGetRecipeQuery,
  useMeQuery,
} from "../../generated/graphql";
import { msToMinutes } from "../../utils/time";
import "./style.css";

interface IngredientListProps {
  ingredients: DetailedRecipeIngredientFragment[];
  closeModal: Function;
}

const IngredientList: React.FC<IngredientListProps> = ({
  ingredients,
  closeModal,
}) => {
  return (
    <div>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Ingredients</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => closeModal()}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonList>
          {ingredients.map((ing) => (
            <IonItem>
              <IonLabel>
                <h2>{ing.ingredient.name}</h2>
                <p>
                  {ing.amount} {ing.displayUnit}
                </p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </div>
  );
};

interface RecipeDetailProps extends RouteComponentProps<{ id: string }> {}

const RecipeDetail: React.FC<RecipeDetailProps> = ({ match }) => {
  const { data: currentUser } = useMeQuery();
  const { data, loading } = useGetRecipeQuery({
    fetchPolicy: "network-only",
    variables: {
      recipeId: parseInt(match.params.id),
    },
  });

  // This makes no sense but Ionic is weird like that — https://ionicframework.com/docs/api/modal#methods
  const handleClose = () => {
    hideIngredients();
  };

  const [showIngredients, hideIngredients] = useIonModal(IngredientList, {
    ingredients: data?.recipe?.recipeIngredients,
    closeModal: handleClose,
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          <IonTitle>{data && data.recipe?.name}</IonTitle>
          <IonButtons slot="end">
            {data?.recipe?.user.username === currentUser?.me?.username && (
              <IonButton routerLink={`/edit/recipe/${match.params.id}`}>
                <IonIcon icon={pencil}></IonIcon>
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {loading || !data?.recipe ? (
          <IonSpinner />
        ) : (
          <>
            <IonCard>
              <IonCardHeader className="padding-bottom-none">
                <IonCardSubtitle>
                  Created @{" "}
                  {new Date(data.recipe.createdAt as string).toLocaleDateString(
                    "en-US",
                    {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    }
                  )}
                </IonCardSubtitle>
                <IonCardTitle>Quick Facts</IonCardTitle>
              </IonCardHeader>

              <IonCardContent className="padding-top-none">
                <ul>
                  <li>Serves {data.recipe.numberOfServings} people</li>
                  <li>
                    Takes {msToMinutes(data.recipe.timeToPrepare)} minutes to
                    prepare
                  </li>
                  <li>
                    Needs {data.recipe.recipeIngredients.length} ingredient
                    {data.recipe.recipeIngredients.length === 1 ? "" : "s"}
                  </li>
                  <li>Cusine: {data.recipe.cuisine}</li>
                </ul>
              </IonCardContent>
            </IonCard>

            <Editor readOnly value={data.recipe.content} />
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
              <IonFabButton>
                <IonIcon icon={add} />
              </IonFabButton>
              <IonFabList side="start">
                <IonFabButton
                  onClick={() =>
                    showIngredients({
                      swipeToClose: true,
                      onDidDismiss: hideIngredients,
                      cssClass: "ingredients",
                    })
                  }
                >
                  <IonIcon icon={receipt} />
                </IonFabButton>
                <IonFabButton>
                  <IonIcon icon={share} />
                </IonFabButton>
              </IonFabList>
            </IonFab>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default RecipeDetail;
