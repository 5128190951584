import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { Navigation } from "../../components/Navigation";
import { useLoginMutation } from "../../generated/graphql";

interface LoginPageProps extends RouteComponentProps<{ id: string }> {}

const LoginPage: React.FC<LoginPageProps> = ({ history }) => {
  const [login] = useLoginMutation();
  const [usernameOrEmail, setUsernameOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const [present] = useIonToast();

  const handleCreate = async () => {
    const response = await login({
      variables: {
        usernameOrEmail,
        password,
      },
    });
    if (response.data?.login.errors) {
      present({
        message: JSON.stringify(response.data.login.errors),
        duration: 2000,
        color: "dark",
      });
    } else if (response.data?.login.user) {
      history.push("/");
    }
  };

  return (
    <IonPage>
      {history.action !== "POP" ? (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      ) : (
        <Navigation hideAddIcon hideUserIcon />
      )}
      <IonContent fullscreen className="ion-padding">
        <h1>Welcome back</h1>
        <IonItem>
          <IonLabel position="floating">Email</IonLabel>
          <IonInput
            required
            name="email"
            value={usernameOrEmail}
            onIonChange={(e) => setUsernameOrEmail(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Password</IonLabel>
          <IonInput
            required
            name="password"
            type="password"
            value={password}
            onIonChange={(e) => setPassword(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonButton type="submit" expand="block" onClick={handleCreate}>
          Log In
        </IonButton>

        <p style={{ textAlign: "right" }}>
          Need an account?{" "}
          <IonRouterLink href="/register">make one instead</IonRouterLink>
        </p>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
