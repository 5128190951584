import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { arrowBackOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import {
  CoreIngredientFragment,
  useCreateIngredientMutation,
} from "../../generated/graphql";
import { withApollo } from "../../utils/apollo";
import { commonUnits } from "../../utils/units";

interface CreateIngredientModalProps {
  closeModalAndSetIngredient: (_: CoreIngredientFragment) => void;
  cancelModal: () => void;
  initialIngredient: string;
}
const CreateIngredientModal: React.FC<CreateIngredientModalProps> = ({
  closeModalAndSetIngredient,
  initialIngredient,
  cancelModal,
}) => {
  const [ingredient, setIngredient] = useState(initialIngredient);
  const [unit, setUnit] = useState("");
  const [amount, setAmount] = useState(0);
  const [showToast] = useIonToast();
  const [createIngredient, { loading, data }] = useCreateIngredientMutation();

  const handleCreate = () => {
    if (ingredient.length < 2) {
      return showToast({
        message: "Please enter a longer ingredient name",
        duration: 3000,
      });
    }
    if (amount <= 0) {
      return showToast({
        message: "Please enter an amount greater than 0",
        duration: 3000,
      });
    }

    createIngredient({
      variables: {
        name: ingredient,
        amount,
        unit,
      },
    });
  };

  useEffect(() => {
    if (!loading && !!data?.createIngredient) {
      closeModalAndSetIngredient(data.createIngredient);
    }
  }, [loading, data, closeModalAndSetIngredient]);
  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Add Ingredient</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={cancelModal}>
              <IonIcon icon={arrowBackOutline}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Ingredient Name</IonLabel>
            <IonInput
              value={ingredient}
              onIonChange={(e) =>
                e.detail.value && setIngredient(e.detail.value)
              }
            >
              {" "}
            </IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Amount</IonLabel>
            <IonInput
              value={amount}
              type="number"
              onIonChange={(e) =>
                e.detail.value && setAmount(parseFloat(e.detail.value))
              }
            >
              {" "}
            </IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Unit</IonLabel>
            <IonSelect
              value={unit}
              okText="Okay"
              cancelText="Dismiss"
              onIonChange={(e) => {
                setUnit(e.detail.value);
              }}
            >
              {commonUnits.map((unit) => (
                <IonSelectOption value={unit.abbr}>
                  {amount === 1 ? unit.singular : unit.plural}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          <IonButton onClick={handleCreate} expand="block">
            Create
          </IonButton>
        </IonList>
      </IonContent>
    </IonPage>
  );
};
export default withApollo(CreateIngredientModal);
