import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonSearchbar,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { search } from "ionicons/icons";
import { useState } from "react";
import { RouteComponentProps } from "react-router";
import RecipeCard from "../../components/RecipeCard";
import { useRecipeSearchLazyQuery } from "../../generated/graphql";
import "./SearchPage.css";

interface SearchProps extends RouteComponentProps {}

const Search: React.FC<SearchProps> = ({ history }) => {
  const [lastRequest, setLastRequest] = useState(new Date());
  const [searchRecipes, { loading, data }] = useRecipeSearchLazyQuery({
    fetchPolicy: "network-only",
  });
  const [searchQuery, setSearchQuery] = useState("");

  const inPlanningContext = history.location.pathname.includes("/planning");

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {inPlanningContext && <IonBackButton defaultHref="/planning" />}
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton
              onClick={() => {
                searchRecipes({
                  variables: {
                    searchString: searchQuery,
                  },
                });
              }}
            >
              <IonIcon slot="icon-only" icon={search} />
            </IonButton>
          </IonButtons>
          <IonSearchbar
            enterkeyhint="search"
            inputMode="search"
            value={searchQuery}
            showClearButton="always"
            type="search"
            onIonClear={(_) => setSearchQuery("")}
            onIonChange={(e) => {
              if (!!e.detail.value) {
                if (new Date().getTime() - lastRequest.getTime() > 2 * 1000) {
                  // no more than 1 request every 2 seconds
                  setSearchQuery(e.detail.value);
                  searchRecipes({
                    variables: { searchString: e.detail.value },
                  });
                  setLastRequest(new Date());
                }
              }
            }}
            placeholder="Search for a recipe name"
          />
        </IonToolbar>
        <IonContent fullscreen>
          {searchQuery.length === 0 && (
            <div className="container">
              <p>Search for anything above!</p>
            </div>
          )}
          {searchQuery.length > 0 && loading && (
            <div className="container">
              <IonSpinner />
            </div>
          )}
          {searchQuery.length > 0 && data?.recipeSearch.length === 0 && (
            <div className="container">
              <p>No Results Found</p>
            </div>
          )}

          <IonGrid>
            <IonRow>
              {loading ? (
                <div className="container">
                  <IonSpinner />
                </div>
              ) : (
                data?.recipeSearch.map((recipe) => {
                  return (
                    <IonCol key={recipe.id} size-sm size="12" sizeLg="3">
                      <RecipeCard
                        recipe={recipe}
                        inPlanningContext={inPlanningContext}
                      />
                    </IonCol>
                  );
                })
              )}
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonHeader>
    </IonPage>
  );
};

export default Search;
