import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Component, ElementType } from "react";

export const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL || "http://localhost:4000"}/graphql`,
  cache: new InMemoryCache(),
  credentials: "include",
});

export const withApollo = (ComponentToRender: ElementType) => {
  return class extends Component {
    render() {
      return (
        <ApolloProvider client={client}>
          <ComponentToRender {...this.props} />
        </ApolloProvider>
      );
    }
  };
};
