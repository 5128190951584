import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { RecipeEditor } from "../../components/RecipeEditor";
import { useCreateRecipeMutation } from "../../generated/graphql";
import "./style.css";

interface CreateRecipeProps extends RouteComponentProps<{ id: string }> {}

const CreateRecipe: React.FC<CreateRecipeProps> = ({ history }) => {
  const [name, setName] = useState("");
  const [numberOfServings, setNumberOfServings] = useState<number>();
  const [timeToPrepare, setTimeToPrepare] = useState<number>();
  const [cuisine, setCuisine] = useState("");
  const [primaryImageUrl, setPrimaryImageUrl] = useState("");
  const [content, setContent] = useState("");
  const [createRecipe] = useCreateRecipeMutation();
  const [present] = useIonToast();

  const handleSave = async () => {
    if (name.length < 2) {
      present({
        message: "Please make your name 2 characters or longer",
        color: "dark",
        duration: 2000,
      });
      return;
    }

    if (!numberOfServings) {
      present({
        message: "Please fill out the number of servings",
        color: "dark",
        duration: 2000,
      });
      return;
    }
    if (!timeToPrepare) {
      present({
        message: "Please fill out the time to prepare",
        color: "dark",
        duration: 2000,
      });
      return;
    }

    if (cuisine.length < 2) {
      present({
        message: "Please enter a cusine longer than 2 characters",
        color: "dark",
        duration: 2000,
      });
      return;
    }

    try {
      const result = await createRecipe({
        variables: {
          name,
          numberOfServings,
          timeToPrepare,
          cuisine,
          primaryImageUrl,
          content,
        },
      });
      if (result.data?.createRecipe) {
        history.push(`/edit/recipe/${result.data?.createRecipe.id}`);
      } else {
        present({
          message: "Something went wrong",
          color: "dark",
          duration: 3000,
        });
      }
    } catch (err) {
      present({
        message: `${err}`,
        color: "dark",
        duration: 2000,
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => handleSave()}>Create</IonButton>
          </IonButtons>
          <IonTitle>Create New Recipe</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonCard>
          <IonCardHeader className="padding-bottom-none">
            <IonCardTitle>Recipe Data</IonCardTitle>
          </IonCardHeader>

          <IonCardContent className="padding-top-none">
            <IonList>
              <IonItem>
                <IonLabel position="floating">Recipe Name</IonLabel>
                <IonInput
                  value={name}
                  placeholder="Dish Name"
                  clearInput
                  onIonChange={(e) => setName(e.detail.value!)}
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating"># of Servings</IonLabel>
                <IonInput
                  type="number"
                  value={numberOfServings}
                  placeholder="# of people this serves"
                  clearInput
                  onIonChange={(e) =>
                    setNumberOfServings(parseInt(e.detail.value!, 10))
                  }
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Preparation Time</IonLabel>
                <IonInput
                  type="number"
                  value={timeToPrepare}
                  placeholder="Enter Time in Minutes"
                  clearInput
                  onIonChange={(e) =>
                    setTimeToPrepare(parseInt(e.detail.value!, 10))
                  }
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Cuisine</IonLabel>
                <IonInput
                  value={cuisine}
                  placeholder="Bengali, Gujarati, Japanese, Persian..."
                  clearInput
                  onIonChange={(e) => setCuisine(e.detail.value!)}
                ></IonInput>
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Primary Image Url</IonLabel>
                <IonInput
                  type="url"
                  value={primaryImageUrl}
                  placeholder="We'll be adding image uploading supoprt soon!"
                  clearInput
                  onIonChange={(e) => setPrimaryImageUrl(e.detail.value!)}
                ></IonInput>
              </IonItem>
            </IonList>
          </IonCardContent>
        </IonCard>

        <RecipeEditor
          onChange={(e) => setContent(e())}
          placeholder={"Write your recipe here!"}
          addIngredient={() => {
            present({
              message: "Please save this recipe to add ingredients",
              duration: 3000,
            });
          }}
        />
      </IonContent>
    </IonPage>
  );
};

export default CreateRecipe;
