import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import update from "immutability-helper";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import AddIngredientModal from "../../components/AddIngredientModal";
import { RecipeEditor } from "../../components/RecipeEditor";
import {
  DetailedRecipeIngredientFragment,
  useGetRecipeQuery,
  useUpdateRecipeMutation,
} from "../../generated/graphql";
import { minutesToMs, msToMinutes } from "../../utils/time";

interface EditRecipeProps extends RouteComponentProps<{ id: string }> {}

const EditRecipe: React.FC<EditRecipeProps> = ({ match, history }) => {
  const recipeId = parseInt(match.params.id);
  const { data: recipeData, loading: getRecipeLoading } = useGetRecipeQuery({
    variables: { recipeId },
    fetchPolicy: "network-only",
  });

  const [name, setName] = useState("");
  const [numberOfServings, setNumberOfServings] = useState<number>();
  const [timeToPrepare, setTimeToPrepare] = useState<number>();
  const [cuisine, setCuisine] = useState("");
  const [primaryImageUrl, setPrimaryImageUrl] = useState("");
  const [serverContent, setServerContent] = useState("");
  const [localContent, setLocalContent] = useState("");
  const [updateRecipe] = useUpdateRecipeMutation();
  const [present] = useIonToast();
  const [ingredient, setIngredient] = useState("");
  const [addIngredientCallback, setAddIngredientCallback] = useState<
    (_: string) => void
  >(() => () => {});
  const [showAddIngredientModal, hideAddIngredientModal] = useIonModal(
    AddIngredientModal,
    {
      initialIngredient: ingredient,
      recipeId,
      cancelModal: () => {
        hideAddIngredientModal();
      },
      closeModalAndSetIngredient: (ing: DetailedRecipeIngredientFragment) => {
        setIngredient(ing.ingredient.name);
        hideAddIngredientModal();
        addIngredientCallback(ing.ingredient.name);

        // if we already have this ingredient, we need to replace it with the new settings
        const index = recipeIngredients.findIndex((ri) => ri.id === ing.id);
        if (index !== -1) {
          const updatedRI = update(recipeIngredients, {
            $splice: [[index, 1, ing]],
          });
          setRecipeIngredients(updatedRI);
        } else {
          setRecipeIngredients(recipeIngredients.concat(ing));
        }
      },
    }
  );
  const [recipeIngredients, setRecipeIngredients] = useState<
    DetailedRecipeIngredientFragment[]
  >([]);

  const [showIngredients, setShowIngredients] = useState(false);
  const toggleIngredients = () => {
    setShowIngredients(!showIngredients);
  };

  useEffect(() => {
    if (recipeData?.recipe) {
      setName(recipeData.recipe.name);
      setNumberOfServings(recipeData.recipe.numberOfServings);
      setCuisine(recipeData.recipe.cuisine);
      setPrimaryImageUrl(recipeData.recipe.primaryImageUrl);
      setServerContent(recipeData.recipe.content);
      setTimeToPrepare(msToMinutes(recipeData.recipe.timeToPrepare));
      setRecipeIngredients(recipeData.recipe.recipeIngredients);
    }
  }, [recipeData]);

  const handleSave = async () => {
    if (name.length < 2) {
      present({
        message: "Please make your name 2 characters or longer",
        color: "dark",
        duration: 2000,
      });
      return;
    }

    if (!numberOfServings) {
      present({
        message: "Please fill out the number of servings",
        color: "dark",
        duration: 2000,
      });
      return;
    }
    if (!timeToPrepare) {
      present({
        message: "Please fill out the time to prepare",
        color: "dark",
        duration: 2000,
      });
      return;
    }

    if (cuisine.length < 2) {
      present({
        message: "Please enter a cusine longer than 2 characters",
        color: "dark",
        duration: 2000,
      });
      return;
    }

    try {
      const result = await updateRecipe({
        variables: {
          id: recipeId,
          name,
          numberOfServings: numberOfServings,
          timeToPrepare: minutesToMs(timeToPrepare),
          cuisine,
          primaryImageUrl,
          content: localContent,
        },
      });
      present({
        message: "Saved changes",
        color: "dark",
        duration: 3000,
      });
      history.replace(`/recipe/${result.data?.updateRecipe.id}`);
    } catch (err) {
      present({
        message: `${err}`,
        color: "dark",
        duration: 2000,
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => handleSave()}>Save</IonButton>
          </IonButtons>
          <IonTitle>Editing Recipe</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {getRecipeLoading || !recipeData?.recipe ? (
          <IonSpinner />
        ) : (
          <>
            <IonCard>
              <IonCardHeader className="padding-bottom-none">
                <IonCardTitle>Recipe Data</IonCardTitle>
              </IonCardHeader>

              <IonCardContent className="padding-top-none">
                <IonList>
                  <IonItem>
                    <IonLabel position="floating">Recipe Name</IonLabel>
                    <IonInput
                      value={name}
                      placeholder="Dish Name"
                      clearInput
                      onIonChange={(e) => setName(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating"># of Servings</IonLabel>
                    <IonInput
                      type="number"
                      value={numberOfServings}
                      placeholder="# of people this serves"
                      clearInput
                      onIonChange={(e) =>
                        setNumberOfServings(parseInt(e.detail.value!, 10))
                      }
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Preparation Time</IonLabel>
                    <IonInput
                      type="number"
                      value={timeToPrepare}
                      placeholder="Enter Time in Minutes"
                      clearInput
                      onIonChange={(e) =>
                        setTimeToPrepare(parseInt(e.detail.value!, 10))
                      }
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Cuisine</IonLabel>
                    <IonInput
                      value={cuisine}
                      placeholder="Bengali, Gujarati, Japanese, Persian..."
                      clearInput
                      onIonChange={(e) => setCuisine(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                  <IonItem>
                    <IonLabel position="floating">Primary Image Url</IonLabel>
                    <IonInput
                      type="url"
                      value={primaryImageUrl}
                      placeholder="We'll be adding image uploading supoprt soon!"
                      clearInput
                      onIonChange={(e) => setPrimaryImageUrl(e.detail.value!)}
                    ></IonInput>
                  </IonItem>
                  <IonButton expand="full" onClick={toggleIngredients}>
                    {showIngredients ? "Hide Ingredients" : "Show Ingredients"}
                  </IonButton>
                </IonList>
                {showIngredients && (
                  <IonList>
                    {recipeIngredients.map((ri) => {
                      return (
                        <IonItem key={ri.id}>{ri.ingredient.name}</IonItem>
                      );
                    })}
                  </IonList>
                )}
              </IonCardContent>
            </IonCard>

            <RecipeEditor
              value={serverContent}
              onChange={setLocalContent}
              placeholder={"Write your recipe here!"}
              addIngredient={(ing, state, match, view, editorCallback) => {
                // modal crap
                (document?.activeElement as any).blur();
                setIngredient(ing);

                setAddIngredientCallback(() => editorCallback);

                // actually show the modal now
                showAddIngredientModal({
                  swipeToClose: true,
                  onDidDismiss: hideAddIngredientModal,
                  cssClass: "addingredient",
                });
              }}
            />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default EditRecipe;
