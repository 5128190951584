import { Extension } from "rich-markdown-editor";
import { Plugin } from "prosemirror-state";
import { InputRule } from "prosemirror-inputrules";

const MAX_MATCH = 500;
// based on the input rules code in Prosemirror, here:
// https://github.com/ProseMirror/prosemirror-inputrules/blob/master/src/inputrules.js
function run(view: any, from: any, to: any, regex: any, handler: any) {
  if (view.composing) {
    return false;
  }
  const state = view.state;
  const $from = state.doc.resolve(from);
  if ($from.parent.type.spec.code) {
    return false;
  }
  const textBefore = $from.parent.textBetween(
    Math.max(0, $from.parentOffset - MAX_MATCH),
    $from.parentOffset,
    null,
    "\ufffc"
  );
  const match = regex.exec(textBefore);
  const tr = handler(state, match, match ? from - match[0].length : from, to);
  if (!tr) return false;
  return true;
}

export default class KeyExtension extends Extension {
  extensionName: string;
  OPEN_REGEX: RegExp;
  CLOSE_REGEX: RegExp;
  callback: Function;

  constructor(
    callback: Function,
    key = "@",
    extensionName = "extension",
    options?: Record<string, any> | undefined
  ) {
    super(options);
    this.extensionName = extensionName;
    // this.OPEN_REGEX = new RegExp(`${key}([0-9a-zA-Z_+-]+)?$`);
    // this.CLOSE_REGEX = new RegExp(
    //   `/${key}([0-9a-zA-Z_+-]*)(${key}[\\w\\s]*)$|^${key}(\\s+)([0-9a-zA-Z_+-]*)|^${key}([0-9a-zA-Z_+-]*)([\\s]+)/`
    // );
    this.OPEN_REGEX = /@([0-9a-zA-Z_+-]+)?$/;
    this.CLOSE_REGEX =
      /:([0-9a-zA-Z_+-]*)(:[\w\s]*)$|^:(\s+)([0-9a-zA-Z_+-]*)|^:([0-9a-zA-Z_+-]*)([\s]+)/;
    this.callback = callback;
  }

  get name() {
    return this.extensionName;
  }
  get plugins() {
    return [
      new Plugin({
        props: {
          handleClick: () => {
            // this.options.onClose();
            return false;
          },
          handleKeyDown: (view: any, event: any) => {
            // Prosemirror input rules are not triggered on backspace, however
            // we need them to be evaluted for the filter trigger to work
            // correctly. This additional handler adds inputrules-like handling.
            if (event.key === "Backspace") {
              // timeout ensures that the delete has been handled by prosemirror
              // and any characters removed, before we evaluate the rule.
              setTimeout(() => {
                const { pos } = view.state.selection.$from;
                return run(
                  view,
                  pos,
                  pos,
                  this.OPEN_REGEX,
                  (state: any, match: any) => {
                    if (match) {
                      this.callback(state, match, view);
                      //   this.options.onOpen(match[1]);
                    } else {
                      // this.options.onClose();
                    }
                    return null;
                  }
                );
              });
            }

            // If the query is active and we're navigating the block menu then
            // just ignore the key events in the editor itself until we're done
            if (
              event.key === "Enter" ||
              event.key === "ArrowUp" ||
              event.key === "ArrowDown" ||
              event.key === "Tab"
            ) {
              const { pos } = view.state.selection.$from;

              return run(
                view,
                pos,
                pos,
                this.OPEN_REGEX,
                (state: any, match: any) => {
                  // just tell Prosemirror we handled it and not to do anything
                  if (match) {
                    this.callback(state, match, view);
                  }
                  return null;
                  // return match ? true : null;
                }
              );
            }

            return false;
          },
        },
      }),
    ];
  }

  inputRules() {
    return [
      // main regex should match only:
      // :word
      new InputRule(this.OPEN_REGEX, (state, match) => {
        if (match && state.selection.$from.parent.type.name === "paragraph") {
          this.callback(state, match, this.editor.view);
        }
        return null;
      }),
      // invert regex should match some of these scenarios:
      // :<space>word
      // :<space>
      // :word<space>
      new InputRule(this.CLOSE_REGEX, (state, match) => {
        if (match) {
          // this.options.onClose();
        }
        return null;
      }),
    ];
  }
}
