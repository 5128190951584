import convert, { Unit } from "convert-units";

export interface UnitDescription {
  abbr: convert.Unit;
  measure: convert.Measure;
  system: convert.System;
  singular: string;
  plural: string;
}

// all possible units, but ordered in terms of what people actually use
export const commonUnits: UnitDescription[] = [
  "g",
  "oz",
  "lb",
  "tsp",
  "Tbs",
  "fl-oz",
  "cup",
  "ml",
  "pnt",
  "qt",
  "gal",
  "l",
  "mm3",
  "cm3",
  "cl",
  "dl",
  "kl",
  "m3",
  "km3",
  "krm",
  "tsk",
  "msk",
  "kkp",
  "glas",
  "kanna",
  "in3",
  "ft3",
  "yd3",
].map((unit) => convert().describe(unit as Unit));
