import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";
import React from "react";
import { DisplayableRecipeFragment } from "../../generated/graphql";
import { msToMinutes } from "../../utils/time";
import "./style.css";

interface RecipeCardProps {
  recipe: DisplayableRecipeFragment;
  inPlanningContext?: boolean;
  amount?: number;
}

const RecipeCard: React.FC<RecipeCardProps> = ({
  recipe,
  inPlanningContext = false,
  amount = 1,
}) => (
  <IonCard
    key={recipe.id}
    routerLink={inPlanningContext ? undefined : `/recipe/${recipe.id}`}
  >
    <div
      className="recipe-card-image-div"
      style={{
        backgroundImage: `url("${recipe.primaryImageUrl}")`,
      }}
    ></div>
    <IonCardHeader>
      <IonCardSubtitle>
        {recipe.cuisine} • {msToMinutes(recipe.timeToPrepare)} minutes • Serves{" "}
        {recipe.numberOfServings * amount}
      </IonCardSubtitle>
      <IonCardTitle>{recipe.name}</IonCardTitle>
      {inPlanningContext && (
        <IonButton
          expand="block"
          routerLink={`/planning/add/${recipe.id}?chooseDate=${
            new URLSearchParams(window.location.search).get("chooseDate") ||
            new Date().toISOString()
          }`}
        >
          Choose
        </IonButton>
      )}
    </IonCardHeader>
  </IonCard>
);

export default RecipeCard;
