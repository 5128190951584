import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { Navigation } from "../../components/Navigation";
import { useRegisterMutation } from "../../generated/graphql";

interface RegisterPageProps extends RouteComponentProps<{ id: string }> {}

const RegisterPage: React.FC<RegisterPageProps> = ({ history }) => {
  const [register] = useRegisterMutation();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleCreate = async () => {
    const response = await register({
      variables: {
        options: {
          email,
          username,
          password,
        },
      },
    });
    if (response.data?.register.errors) {
      // o no errors
      alert("oof");
    } else if (response.data?.register.user) {
      // worked
      alert("nice");
    }
  };

  return (
    <IonPage>
      {history.action !== "POP" ? (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      ) : (
        <Navigation hideAddIcon hideUserIcon />
      )}
      <IonContent fullscreen className="ion-padding">
        <h1>Join the movement</h1>
        <IonItem>
          <IonLabel position="floating">Email</IonLabel>
          <IonInput
            required
            name="email"
            value={email}
            type="email"
            onIonChange={(e) => setEmail(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Username</IonLabel>
          <IonInput
            required
            name="username"
            value={username}
            onIonChange={(e) => setUsername(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel position="floating">Password</IonLabel>
          <IonInput
            required
            name="password"
            type="password"
            value={password}
            onIonChange={(e) => setPassword(e.detail.value!)}
          ></IonInput>
        </IonItem>
        <IonButton type="submit" expand="block" onClick={handleCreate}>
          Create Account
        </IonButton>

        <p style={{ textAlign: "right" }}>
          Already have an account?{" "}
          <IonRouterLink href="/login">login instead</IonRouterLink>
        </p>
      </IonContent>
    </IonPage>
  );
};

export default RegisterPage;
