import { addDays, format } from "date-fns";
export const msToMinutes = (ms: number) => {
  return ms / 60000;
};

export const minutesToMs = (mins: number) => {
  return mins * 60000;
};

/**
 * Returns all days from a range (left must be less than right)
 * @param middleDate
 * @param left how many days before the middle date (or after it)
 * @param right how many days after the middle date (or before it)
 */
export const daysFromRange = (
  middleDate: Date,
  left: number,
  right: number
) => {
  const days: Date[] = [];
  for (let j = left; j <= right; j++) {
    days.push(addDays(middleDate, j));
  }
  return days;
};

/**
 * Checks to see if the two dates are the same date (day, month, year)
 */
export const isSameDate = (date1: Date, date2: Date): boolean => {
  return format(date1, "d iii y") === format(date2, "d iii y");
};
