import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRippleEffect,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { addDays, compareAsc, format } from "date-fns";
import {
  addCircleOutline,
  cart,
  chevronBack,
  chevronForward,
} from "ionicons/icons";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  DisplayPlannedMealFragment,
  useGetPlannedMealsQuery,
} from "../../generated/graphql";
import { daysFromRange, isSameDate } from "../../utils/time";
import "./PlanningPage.css";
interface PlannedMealsForDate {
  date: Date;
  plannedMeals: DisplayPlannedMealFragment[];
}

interface PlanningPageProps extends RouteComponentProps {}
const PlanningPage: React.FC<PlanningPageProps> = ({ history }) => {
  const [left, setLeft] = useState(-1);
  const [right, setRight] = useState(4);
  const [now] = useState(new Date());

  const [days, setDays] = useState(daysFromRange(now, left, right));

  useEffect(() => {
    setDays(daysFromRange(now, left, right));
  }, [left, now, right]);

  const { data } = useGetPlannedMealsQuery({
    variables: {
      startDate: addDays(now, left),
      endDate: addDays(now, right),
    },
    fetchPolicy: "cache-and-network",
  });

  const navigateToAddItem = (selectedDate: Date = now) => {
    history.push(`/planning/search?chooseDate=${selectedDate.toISOString()}`);
  };
  const topPart = (
    <IonHeader>
      <IonToolbar>
        <IonTitle>August</IonTitle>
        <IonButtons slot="secondary">
          <IonButton
            onClick={() => {
              setLeft(-1);
              setRight(4);
            }}
          >
            Today
          </IonButton>
        </IonButtons>
        <IonButtons slot="end">
          <IonButton routerLink={`/planning/search`}>
            <IonIcon icon={addCircleOutline} size="large" />
          </IonButton>
          <IonButton routerLink="/planning/view">
            <IonIcon icon={cart} size="large" />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );

  if (!data || !data.plannedMeals) {
    return (
      <IonPage>
        {topPart}
        {/* <IonSpinner /> */}
      </IonPage>
    );
  }

  const formattedPlanMealData = days.map(
    (d): PlannedMealsForDate => ({
      date: d,
      plannedMeals: [],
    })
  );

  // data exists now, and we need to fit it into formatted meal plan data
  for (const plannedMeal of data.plannedMeals) {
    // check to see if its worth traversing
    if (
      compareAsc(days[0], new Date(plannedMeal.date)) <= 0 &&
      compareAsc(days[days.length - 1], new Date(plannedMeal.date)) >= 0
    ) {
      for (const dayPlan of formattedPlanMealData) {
        if (isSameDate(dayPlan.date, new Date(plannedMeal.date))) {
          dayPlan.plannedMeals.push(plannedMeal);
        }
      }
    }
  }

  let plan: JSX.Element[] = [];
  const maxMealsPerDay =
    formattedPlanMealData.reduce(
      (acc, curr) => Math.max(acc, curr.plannedMeals.length),
      0
    ) + 1;
  console.log(maxMealsPerDay);

  for (let row = 0; row < maxMealsPerDay; row++) {
    const rowElements: JSX.Element[] = [];
    // push one empty for the left chevron
    rowElements.push(
      <IonCol className="recipeBoxCol hide">
        <div className="recipeBox">not visible</div>
      </IonCol>
    );
    for (let day = 0; day < formattedPlanMealData.length; day++) {
      const dayObj = formattedPlanMealData[day];
      if (row < dayObj.plannedMeals.length) {
        // there is a meal
        const plannedMeal = dayObj.plannedMeals[row];
        rowElements.push(
          <IonCol
            onClick={() => {
              history.push(`/planning/${plannedMeal.id}`);
            }}
            className="recipeBoxCol ion-activatable ripple-parent"
          >
            <div className="recipeBox actual-recipe">
              {plannedMeal.recipe.name}
            </div>
            <IonRippleEffect />
          </IonCol>
        );
      } else {
        if (row === dayObj.plannedMeals.length) {
          // the first index without an entry
          // for the first non placed item, we want to add an "add a recipe" box
          rowElements.push(
            <IonCol
              className="recipeBoxCol ion-activatable ripple-parent"
              onClick={() => navigateToAddItem(dayObj.date)}
            >
              <div className="recipeBox dashed">Add a recipe</div>
              <IonRippleEffect />
            </IonCol>
          );
        } else {
          // put empty filler
          rowElements.push(
            <IonCol className="recipeBoxCol hide">
              <div className="recipeBox">not visible</div>
            </IonCol>
          );
        }
      }
    }

    // push one empty for the right chevron
    rowElements.push(
      <IonCol className="recipeBoxCol hide">
        <div className="recipeBox">Add a recipe</div>
      </IonCol>
    );

    plan.push(<IonRow>{rowElements}</IonRow>);
  }
  // we have every single day within the range, now we need to sort it

  return (
    <IonPage>
      {topPart}

      <IonContent>
        <IonRow className="weekNav">
          <IonCol
            className="ion-activatable ripple-parent"
            onClick={() => {
              console.log(`clicked chevronLeft`);
              setLeft(left - 1);
              setRight(right - 1);
            }}
          >
            <IonIcon icon={chevronBack} />
            <IonRippleEffect></IonRippleEffect>
          </IonCol>
          {formattedPlanMealData
            .map((day) => day.date)
            .map((date) => {
              const dayString = format(date, "iii"); //.charAt(0);
              const dayOfMonth = format(date, "d");
              return (
                <IonCol
                  key={dayOfMonth}
                  className="ion-activatable ripple-parent"
                  onClick={() => {
                    console.log(`clicked ${dayString} ${dayOfMonth}`);
                  }}
                >
                  <div className="dayAndDate">
                    <span className="dayText">{dayString}</span>
                    <div className="circle">{dayOfMonth}</div>
                  </div>
                  <IonRippleEffect></IonRippleEffect>
                </IonCol>
              );
            })}

          <IonCol
            className="ion-activatable ripple-parent"
            onClick={() => {
              console.log(`clicked chevronRight`);
              setLeft(left + 1);
              setRight(right + 1);
            }}
          >
            <IonIcon icon={chevronForward} />
            <IonRippleEffect></IonRippleEffect>
          </IonCol>
        </IonRow>
        {plan.map((item) => (
          <>{item}</>
        ))}
      </IonContent>
    </IonPage>
  );
};

export default PlanningPage;
